import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

// markup
const failure = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | Payment Failure' />
      <Layout>
        <div className='container'>
          <div className='row '>
            <div className='col-md-12'>
              <section className='section'>
                <div className='section-title'>
                  <h3 className='text-start'>
                    <br /> Payment <span className='red'>Failed</span>
                  </h3>
                </div>
                <h5>Sorry, the payment did not go through!! Please try again!!</h5>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default failure;
